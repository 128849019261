import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URL } from './../../constants';
import { axiosBaseQuery, providesTags } from './baseQuery';
import { AnyCnameRecord } from 'node:dns';

export const reportApi = createApi({
  reducerPath: "reportApi",
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getBuyOrdersReport: builder.query<AnyCnameRecord, void>({
      query: (botId) => ({
        url: `/reports/orders/buy/${botId}`,
        method: "GET",
      }),
    }),
    getSellOrdersReport: builder.query<AnyCnameRecord, void>({
      query: (botId) => ({
        url: `/reports/orders/sell/${botId}`,
        method: "GET",
      }),
    }),
    getBackTestingReport: builder.mutation<any, number[]>({
      query: (idList) => ({
        url: "/reports/backtesting",
        method: "POST",
        data: { id_list: idList },
      }),
    }),
  }),
});

export const {
    useLazyGetBuyOrdersReportQuery,
    useLazyGetSellOrdersReportQuery,
    useGetBackTestingReportMutation
} = reportApi;
