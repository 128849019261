import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URL } from './../../constants';
import { axiosBaseQuery } from './baseQuery';
import { providesTags } from './baseQuery';

interface ISignalLego { }

const tag = 'SignalLego';

export const signalLegoApi = createApi({
    reducerPath: 'signalLegoApi',
    baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
    tagTypes: [tag],
    endpoints: (builder) => ({
        getSignals: builder.query<any[], void>({
            query: () => ({ url: '/signalstrategy/lego', method: 'GET' }),
            providesTags: providesTags(tag),
        }),
        getSingleSignal: builder.query<any, number>({
            query: (id) => ({ url: `/signalstrategy/lego/${id}`, method: 'GET' }),
            providesTags: (result, error, id) => [{ type: tag, id }],
        }),
        getIndicators: builder.query<any[], void>({
            query: () => ({ url: '/indicators', method: 'GET' }),
        }),
        addSignal: builder.mutation<any, Partial<any>>({
            query: (data) => ({
                url: '/signalstrategy/lego',
                method: 'POST',
                data,
            }),
            invalidatesTags: [{ type: tag, id: 'LIST' }],
        }),
        editSignal: builder.mutation<any, { id: number; data: Partial<any> }>({
            query: ({ id, data }) => ({
                url: `/signalstrategy/lego/${id}`,
                method: 'PUT',
                data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: tag, id }],
        }),
        removeSignal: builder.mutation<{ success: boolean }, number>({
            query: (id) => ({
                url: `/signalstrategy/lego/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: tag, id }],
        }),
    }),
});

export const {
    useGetSignalsQuery,
    useGetSingleSignalQuery,
    useGetIndicatorsQuery,
    useAddSignalMutation,
    useEditSignalMutation,
    useRemoveSignalMutation
} = signalLegoApi;
