import { useState } from 'react';
import { useAddCollectorMutation, useEditCollectorMutation, useGetCollectorByIdQuery } from '../../../redux/api/collectorApi';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import StyledModal from "../../StyledModal/StyledModal";
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import InputLabel from "@mui/material/InputLabel";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { candleSizes } from "./const";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from "@mui/material/FormControlLabel";
import "./styles.scss";

const DataCollectionModal = ({ open, handleClose, isEditMode, editId }) => {
    const { data: editData } = useGetCollectorByIdQuery(editId, {
        skip: !editId
    });
    const [addCollector] = useAddCollectorMutation();
    const [editCollector] = useEditCollectorMutation();
    const [isLoading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: editData?.name || '',
            period_type: editData?.period_type?.toLowerCase() || '',
            period: editData?.period || 0,
            date: editData?.date || 0,
            granularity: editData?.granularity || 0,
        },
        onSubmit: async (values) => {
            setLoading(true);
            try {
                if (isEditMode && editId) {
                    editCollector({ id: editId, data: values }) ;
                } else {
                    addCollector(values).unwrap() ;
                }
            } catch (error) {
                console.error('An error occurred. Please check the input data.');
            } finally {
                setLoading(false);
                handleClose()
            }
        },
    });

    const handleCandleSizeChange = (event) => {
        const { value } = event.target;
        formik.setFieldValue('granularity', value);
    };

    return (
        <StyledModal
            open={open}
            handleClose={handleClose}
            title={`${isEditMode ? 'Edit Data Collection Config' : 'Add Data Collection Config'}`}
        >
            <DialogContent>
                <form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                        sx={{ my: 2, mb: 1 }}
                        label="Name"
                        id="standard-size-normal"
                        {...formik.getFieldProps("name")}
                    />
                    <InputLabel style={{ marginTop: 5 }}>Period Type</InputLabel>
                    <RadioGroup
                        sx={{ my: 0, mb: 1 }}
                        row
                        {...formik.getFieldProps("period_type")}
                    >
                        <FormControlLabel value="day" control={<Radio />} label="day" />
                        <FormControlLabel value="min" control={<Radio />} label="min" />
                    </RadioGroup>
                    <TextField
                        sx={{ my: 2 }}
                        label="Period"
                        id="standard-size-normal"
                        type="number"
                        {...formik.getFieldProps("period")}
                    />
                    <InputLabel sx={{ mb: 1 }}>Granularity (Candle Size)</InputLabel>
                    <Select
                        value={formik.values.granularity}
                        onChange={handleCandleSizeChange}
                        {...formik.getFieldProps("granularity")}
                    >
                        {candleSizes.map(cs => (
                            <MenuItem key={cs.value} value={cs.value}>{cs.label}</MenuItem>
                        ))}
                    </Select>
                </form>
            </DialogContent>
            <DialogActions>
                <Button style={{ color: '#ebebeb' }} onClick={handleClose}>Cancel</Button>
                <Button
                    type="submit"
                    onClick={formik.submitForm}
                    disabled={isLoading}
                    style={{ color: '#32c3b3' }}
                >
                    {isEditMode ? 'Save' : 'Add'}
                </Button>
            </DialogActions>
        </StyledModal>
    );
};

export default DataCollectionModal;
