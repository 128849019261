
import AppTable from "../../components/Table/AppTable";
import { useConfirmAccountMutation, useGetAccountConfirmationQuery } from "../../redux/api/adminApi";
import { getAccountConfirmationColumns } from "./columns/columns";
import styles from "./admin.module.scss";
import DataCollection from "../../components/BotBuilder/DataCollection/DataCollection";

const AdminPanel: React.FC = () => {
  const {data: confirmationAccounts} = useGetAccountConfirmationQuery()
  const [confirmAccount] = useConfirmAccountMutation();
  const columns = getAccountConfirmationColumns(confirmAccount);

  return (
    <div className={styles.adminPanel}>
        <div className={styles.title}>Account Confirmation</div>  
        <AppTable 
          data={confirmationAccounts || []}
          columns={columns}
        />
        <DataCollection />
    </div>
  );
};

export default AdminPanel;
