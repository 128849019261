import React from 'react';
import Button from '@mui/material/Button';
import { SxProps, Theme } from '@mui/material/styles';
import styles from './app-button.module.scss';

interface AppButtonProps {
    variant: 'primary' | 'secondary' | 'danger' | 'ghost';
    onClick?: () => void;
    disabled?: boolean;
    startIcon?: React.ReactNode;
    children: React.ReactNode;
    sx?: SxProps<Theme>;
    type?: 'button' | 'submit' | 'reset';
    fullWidth?: boolean;
    size?: 'small' | 'medium' | 'large'
}

const AppButton: React.FC<AppButtonProps> = ({
    variant,
    onClick,
    disabled = false,
    startIcon,
    children,
    type = 'button',
    fullWidth,
    size
}) => {
    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            startIcon={startIcon}
            className={`${styles.button} ${styles[variant]}`}
            sx={{width: `${fullWidth ? '100%' : 'auto'}`}}
            type={type}
            size={size}
        >
            {children}
        </Button>
    );
};

export default AppButton;
