export const candleSizes = [
    { value: 60, label: "1m" },
    { value: 300, label: "5m" },
    { value: 900, label: "15m" },
    { value: 1800, label: "30m" },
    { value: 3600, label: "1h" },
    { value: 14400, label: "4h" },
    { value: 21600, label: "6h" },
    { value: 43200, label: "12h" }, 
    { value: 86400, label: "1d" },
    { value: 604800, label: "1w" },
    { value: 2592000, label: "1M" }, 
];
