import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#08A858"
      d="M9 1.5C4.867 1.5 1.5 4.867 1.5 9s3.367 7.5 7.5 7.5 7.5-3.367 7.5-7.5S13.133 1.5 9 1.5Zm3.585 5.775-4.253 4.252a.562.562 0 0 1-.795 0L5.415 9.405a.566.566 0 0 1 0-.795.566.566 0 0 1 .795 0l1.725 1.725L11.79 6.48a.566.566 0 0 1 .795 0 .566.566 0 0 1 0 .795Z"
    />
  </svg>
)
export default SvgComponent
