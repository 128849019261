import { Outlet, Navigate, useLocation } from 'react-router-dom';

const UserOnline = ({ children }) => {
    const accessToken = localStorage.getItem('access_token');
    const location = useLocation();

    if (!accessToken) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children ? children : <Outlet />;
};

export default UserOnline;