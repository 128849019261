import React from 'react';
import StyledModal from "../StyledModal/StyledModal";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const ConfirmationDeleteModal = ({
    open,
    handleClose,
    onRemove
}) => {
    return (
        <StyledModal
            open={open}
            handleClose={handleClose}
        >
            <DialogContent>
                Are you sure you want to delete? This action cannot be undone.
            </DialogContent>
            <DialogActions>
                <Button style={{color: '#ebebeb'}} onClick={handleClose}>Cancel</Button>
                <Button style={{color: '#db5858'}} onClick={() => {
                    onRemove()
                    handleClose()
                }}>Delete</Button>
            </DialogActions>
        </StyledModal>
    );
};

export default ConfirmationDeleteModal;
