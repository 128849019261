import React from 'react';
import brandLogo from './../../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import styles from "./login.module.scss"

const LoginWrapper: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.loginContainer}>
            <img src={brandLogo} alt="Botico Brand Logo" className={styles.brandLogo} />
            <div className={styles.loginWrapper}>
                <h1 className={styles.title}>{t('pages.login.title')}</h1>
                <p className={styles.description} dangerouslySetInnerHTML={{ __html: t('pages.login.description') }} />
            </div>
        </div>
    );
};

export default LoginWrapper;
