import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

interface StyledModalProps {
  children: React.ReactNode;
  handleClose: () => void;
  open: boolean;
  title?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}

const StyledModal: React.FC<StyledModalProps> = ({
  children,
  handleClose,
  open,
  title = "",
  maxWidth
}) => {
  return (
    <Dialog
      fullWidth
      scroll="paper"
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      className="rounded-xl"
    >
      {title && (
        <DialogTitle className="text-white">{title}</DialogTitle>
      )}
      {children}
    </Dialog>
  );
};

export default StyledModal;
