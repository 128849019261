import React from 'react';
import LoginForm from './LoginForm';
import styles from "./login.module.scss"
import LanguageSwitcher from '../../components/LangSwitcher/LangSwitcher';
import LoginWrapper from './LoginWrapper';

const Login: React.FC = () => {
  return (
    <div className={styles.login}>
      <LoginWrapper />
      <div className={styles.loginFormWrapper}>
          <LanguageSwitcher variant='inline'/>
          <LoginForm />
      </div>
    </div>
  );
};

export default Login;
