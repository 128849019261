import React from "react";
import StyledModal from "../StyledModal/StyledModal";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AppButton from "../Button/AppButton";

type Props = {
  isOpen: boolean;
  content?: string;
  onClose: () => void;
  onConfirm: () => void;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
};

const ConfirmationModal = ({ isOpen, onClose, onConfirm, content, maxWidth }: Props) => {
  return (
    <StyledModal open={isOpen} handleClose={onClose}maxWidth={maxWidth}>
      <DialogContent className="bg-primary-800">{content}</DialogContent>
      <DialogActions className="bg-primary-800 p-4">
        <AppButton variant="secondary" size="small" onClick={onClose}>
          Cancel
        </AppButton>
        <AppButton
          variant="danger"
          size="small"
          onClick={() => {
            onClose();
            onConfirm();
          }}
        >
          Delete
        </AppButton>
      </DialogActions>
    </StyledModal>
  );
};

export default ConfirmationModal;
