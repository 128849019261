import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 19}
    height={props.height || 18}
    viewBox="0 0 19 18"
    {...props}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill={props.fill ||"#189707"}
        d="M16.462 6.439h-.433V4.353l-.003-.04a.346.346 0 0 0-.084-.228L12.472.121l-.003-.003a.341.341 0 0 0-.094-.072.369.369 0 0 0-.07-.03L12.288.01a.355.355 0 0 0-.081-.01h-8.53a.707.707 0 0 0-.705.706v5.732h-.433c-.557 0-1.008.452-1.008 1.01v5.246a1.01 1.01 0 0 0 1.008 1.009h.433v3.591c0 .39.316.706.705.706h11.648c.389 0 .705-.317.705-.706v-3.591h.433a1.01 1.01 0 0 0 1.008-1.01V7.448c0-.557-.451-1.008-1.008-1.008ZM3.676.706h8.177v3.611c0 .195.158.353.353.353h3.118v1.768H3.676V.706ZM8.99 10.28c-.876-.305-1.446-.79-1.446-1.555 0-.9.75-1.588 1.994-1.588.594 0 1.032.125 1.345.266l-.266.962a2.5 2.5 0 0 0-1.103-.25c-.516 0-.766.234-.766.508 0 .336.297.485.978.743.93.344 1.368.828 1.368 1.571 0 .884-.68 1.635-2.127 1.635-.602 0-1.197-.157-1.494-.32l.243-.986c.32.164.813.328 1.321.328.548 0 .837-.227.837-.571 0-.329-.25-.516-.884-.742Zm-6.21-.344c0-1.798 1.284-2.8 2.878-2.8.618 0 1.087.126 1.299.236l-.243.946a2.583 2.583 0 0 0-1-.195c-.947 0-1.682.57-1.682 1.743 0 1.055.625 1.72 1.69 1.72.359 0 .758-.078.992-.172l.18.93c-.219.11-.711.228-1.353.228-1.822 0-2.76-1.135-2.76-2.636Zm12.544 7.166H3.676v-3.4h11.648v3.4Zm-.789-4.609h-1.392l-1.689-5.27h1.306l.641 2.229c.18.625.344 1.227.47 1.884h.023c.133-.633.297-1.259.477-1.861l.672-2.252h1.268l-1.776 5.27Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h18v18H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
