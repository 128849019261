import { lazy } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from "../../components/Layout/Layout";
import UserOnline from "../../UserOnline";
import UserOffline from "../../UserOffline";
import ErrorPage from "../ErrorPage/ErrorPage";
import Login from "../Login/Login";
import Registration from "../Register/Registration";
import AdminPanelRoute from "../AdminPanel/AdminPanelRoute";


const Dashboard = lazy(() => import('../Dashboard/Dashboard'));
const Settings = lazy(() => import('../Settings/Settings'));
const BotBuilder = lazy(() => import('../BotBuilder/BotBuilder'));
const BackTesting = lazy(() => import('../BackTesting/Backtesting/BackTesting'));
const Instance = lazy(() => import('../BackTesting/Instance/Instance'));
const TradeHistory = lazy(() => import('../TradeHistory/TradeHistory'));
const BotDetails = lazy(() => import('../BotDetails/BotDetails'));
const SignalStrategyMain = lazy(() => import('../SignalStrategy/SignalStrategyMain'));
const Account = lazy(() => import('../Account/Account'));
const ApiKey = lazy(() => import('../ApiKey/ApiKey'));
const ConfirmationPage = lazy(() => import('../ConfirmationPage/ConfirmationPage'));
const RiskManagementStrategy = lazy(() => import('../RiskManagementStrategy/RiskManagementStrategy'));
const BackTestingCollector = lazy(() => import('../BackTesting/Collector/BacktestingCollector'));

const AppRoutes: React.FC = () => {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route
          path="/login"
          element={
            <UserOffline>
              <Login />
            </UserOffline>
          }
        />
        <Route
          path="/registration"
          element={
            <UserOffline>
              <Registration />
            </UserOffline>
          }
        />
        <Route
          path="/account/confirm/:token"
          element={
            <UserOffline>
              <ConfirmationPage />
            </UserOffline>
          }
        />
        <Route
          element={
            <UserOnline>
              <Layout />
            </UserOnline>
          }
        >
          <Route path="/bot-builder" element={<BotBuilder />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/account" element={<Account />} />
          <Route path="/account/apikey" element={<ApiKey />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/back-testing" element={<BackTesting />} />
          <Route path="/back-testing/instance/:id" element={<Instance />} />
          <Route path="/trade-history" element={<TradeHistory />} />
          <Route path="/bot-builder/bot/:id" element={<BotDetails />} />
          <Route path="/signal-strategy" element={<SignalStrategyMain />} />
          <Route path="/admin" element={<AdminPanelRoute />} />
          <Route path="/risk-management" element={<RiskManagementStrategy />} />
          <Route
            path="/back-testing/collector"
            element={<BackTestingCollector />}
          />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    );
};

export default AppRoutes;
