import * as yup from 'yup';

export const validationRegisterSchema = yup.object().shape({
    firstname: yup.string().required('Firstname is required'),
    lastname: yup.string().required('Lastname is required'),
    username: yup.string().required('Username is required'),
    email: yup.string()
    .required('Email is required')
    .email('Email must contain an "@" symbol and a valid domain')
    .max(255, 'Email cannot be longer than 255 characters'),
    password: yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9!@#$%^&*]/, 'Password must contain at least one number or symbol'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), ''], 'Passwords must match')
        .required('Confirm Password is required'),
        terms: yup.boolean()
        .oneOf([true], 'Please check this box to confirm that you accept the Terms and Conditions and Privacy Policy.')
        .required('Please check this box to confirm that you accept the Terms and Conditions and Privacy Policy.'),
}); 