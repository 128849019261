import React from 'react';
import styles from "./../Login/login.module.scss"
import LanguageSwitcher from '../../components/LangSwitcher/LangSwitcher';
import LoginWrapper from '../Login/LoginWrapper';
import RegisterForm from './RegisterForm';

const Registration: React.FC = () => {
  return (
    <div className={styles.login}>
      <LoginWrapper />
      <div className={styles.loginFormWrapper}>
          <LanguageSwitcher variant='inline'/>
          <RegisterForm />
      </div>
    </div>
  );
};

export default Registration;
