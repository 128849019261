import { createApi } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./../../constants";
import { axiosBaseQuery, providesTags } from "./baseQuery";

const tag = "Instance";

export const backTestCOllectorApi = createApi({
  reducerPath: "backTestCOllectorApi",
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  tagTypes: [tag],
  endpoints: (builder) => ({
    getBackTestingCollector: builder.query<any, void>({
      query: () => ({ url: "/backtesting/collector/config", method: "GET" }),
      transformResponse: (response: any) => response.reverse(),
      providesTags: providesTags(tag),
    }),
    addBackTestingCollector: builder.mutation<any, Partial<any>>({
      query: (data) => ({
        url: "/backtesting/collector/config",
        method: "POST",
        data,
      }),
      invalidatesTags: [{ type: tag, id: "LIST" }],
    }),
    deleteBackTestingCollector: builder.mutation<{ success: boolean }, number>({
      query: (id) => ({
        url: `/backtesting/collector/config/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: tag, id }],
    }),
  }),
});

export const {
  useGetBackTestingCollectorQuery,
  useAddBackTestingCollectorMutation,
  useDeleteBackTestingCollectorMutation,
} = backTestCOllectorApi;

