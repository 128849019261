import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const axiosFetcher = async (url: string) => {
  try {
    const response = await axios.get(url);
    return { data: response.data };
  } catch (error: any) {
    return { error: { status: error.response?.status, data: error.response?.data } };
  }
};

export const externalApi = createApi({
  reducerPath: "externalApi",
  baseQuery: ({ url }: { url: string }) => axiosFetcher(url),
  endpoints: (builder) => ({
    getMarketChart: builder.query<any, { id: string; days: number }>({
      query: ({ id, days }) => ({
        url: `https://api.coingecko.com/api/v3/coins/${id}/market_chart?vs_currency=usd&days=${days}`,
      }),
    }),
    getMarketData: builder.query<any, string>({
      query: (id) => ({
        url: `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${id}`,
      }),
    }),
    getMarketDominance: builder.query<any, void>({
      query: () => ({
        url: `https://api.coingecko.com/api/v3/global?vs_currency=usd`,
      }),
    }),
    getFearAndGreedIndex: builder.query<any, void>({
      query: () => ({
        url: `https://api.alternative.me/fng/?limit=1`,
      }),
    }),
  }),
});

export const {
  useGetMarketChartQuery,
  useGetMarketDataQuery,
  useGetMarketDominanceQuery,
  useGetFearAndGreedIndexQuery,
} = externalApi;
