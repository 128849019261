import React, { useState, useEffect } from 'react';
import i18n from '../../i18n';
import styles from './styles.module.scss';
import { Select, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';

interface LanguageSwitcherProps {
  variant: 'inline' | 'menu';
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ variant }) => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(
    i18n.language || localStorage.getItem('language') || 'en'
  );

  useEffect(() => {
    const languageChangeHandler = () => setCurrentLanguage(i18n.language);
    i18n.on('languageChanged', languageChangeHandler);

    return () => {
      i18n.off('languageChanged', languageChangeHandler);
    };
  }, []);

  const handleChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    setCurrentLanguage(lng);
  };

  return (
    <div className={styles.langSwitcher}>
      {variant === 'inline' && (
        <>
          <button 
            onClick={() => handleChangeLanguage('en')} 
            className={currentLanguage === 'en' ? styles.active : ''}
          >
            English
          </button>
          <button 
            onClick={() => handleChangeLanguage('es')} 
            className={currentLanguage === 'es' ? styles.active : ''}
          >
            Español
          </button>
        </>
      )}

      {variant === 'menu' && (
        <FormControl variant="outlined" className={styles.selectControl}>
          <Select
            value={currentLanguage}
            onChange={(e: SelectChangeEvent<string>) => handleChangeLanguage(e.target.value as string)}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiSelect-select': {
                padding: '8px 16px',
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: '#01002D',
                },
              },
            }}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="es">Español</MenuItem>
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default LanguageSwitcher;
