import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URL } from './../../constants'
import { axiosBaseQuery } from './baseQuery';

interface OrderStatistics {
  total_buy_closed_order_number: number;
  total_buy_open_order_number: number;
  total_buy_order_number: number;
  total_sell_closed_order_number: number;
  total_sell_open_order_number: number;
  total_sell_order_number: number;
}

export const statisticsApi = createApi({
  reducerPath: 'statisticsApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getOrderStatistics: builder.query<OrderStatistics, void>({
      query: () => ({ url: '/stats', method: 'GET' }),
    }),
  }),
});

export const { useGetOrderStatisticsQuery } = statisticsApi;