import React, { useState, useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual'
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getFilteredRowModel
} from '@tanstack/react-table'
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Table as MuiTable } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import VTSearchInput from "./VTSearchInput";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ConfirmationDeleteModal from "../ConfirmationModal/ConfirmationDeleteModal";


const visibleSearchColumns = {
    name: 'name',
}

const VirtualizedTable = ({
    data,
    columns,
    onRemoveRowSelection,
    onGenerateRowReport,
    topPanel = false,
    size
}) => {
    const [columnFilters, setColumnFilters] = useState([])
    const [rowSelection, setRowSelection] = React.useState({})
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
            columnFilters,
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnFiltersChange: setColumnFilters,
        onRowSelectionChange: setRowSelection,
        enableRowSelection: true,
        debugTable: false,
    })

    const arraySelectedId = table.getSelectedRowModel().rows.map(({ original }) => original.id)

    const { rows } = table.getRowModel()
    const parentRef = useRef(null)

    const virtualizer = useVirtualizer({
        count: rows.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 50,
    })

    return (
        <>
            <TableContainer component={Paper} ref={parentRef} className="mt-5">
                <>
                    {topPanel ? (
                        <div className="flex justify-between items-center p-4">
                            <span>{`Total row: ${data.length}`}</span>
                            <div className="flex">
                                <Button
                                    variant="contained"
                                    className={`min-w-0 mr-2 ${!arraySelectedId.length ? "bg-brand-gray text-primary-black" : "bg-primary-danger "}`}
                                    onClick={() => setDeleteModalOpen(true)}
                                    disabled={!arraySelectedId.length}
                                >
                                    <DeleteForeverIcon />
                                </Button>
                                <Button
                                    variant="contained"
                                    className={`min-w-0 mr-2 ${!arraySelectedId.length ? "bg-brand-gray text-primary-black" : "bg-neo "}`}
                                    onClick={() => {
                                        onGenerateRowReport(arraySelectedId);
                                    }}
                                    disabled={!arraySelectedId.length}
                                >
                                    <FileDownloadIcon />
                                </Button>
                            </div>
                        </div>
                    ) : null}
                    <MuiTable>
                        <TableHead className="bg-[#2d5b5e]">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        const columnFilterValue = header.column.getFilterValue() ?? ''
                                        return (
                                            <TableCell
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                align="center"
                                                size={size}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <>
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {header.column.getCanFilter() && visibleSearchColumns[header.column.id] ? (
                                                            <VTSearchInput
                                                                type="text"
                                                                value={columnFilterValue}
                                                                onChange={value => header.column.setFilterValue(value)}
                                                                placeholder={`Search...`}
                                                                list={header.column.id + 'list'}
                                                            />
                                                        ) : null}
                                                    </>
                                                )}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody>
                            {virtualizer.getVirtualItems().map((virtualRow, index) => {
                                const row = rows[virtualRow.index]
                                return (
                                    <React.Fragment key={row.id}>
                                        <TableRow
                                            key={row.id}
                                        >
                                            {row.getVisibleCells().map((cell) => {
                                                return (
                                                    <TableCell key={cell.id} align="center" size={size}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext(),
                                                        )}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </React.Fragment>
                                )
                            })}
                        </TableBody>
                    </MuiTable>

                    <div className="flex justify-end">
                    <span className="flex items-center gap-1 text-sm font-thin">
                      <div>Page</div>
                      <span>
                        {table.getState().pagination.pageIndex + 1} of{' '}
                          {table.getPageCount()}
                      </span>
                    </span>
                        <Button
                            className={`${table.getCanPreviousPage() ? 'text-neo' : ''}`}
                            onClick={() => table.setPageIndex(0)}
                            disabled={!table.getCanPreviousPage()}
                        >
                            {'<<'}
                        </Button>
                        <Button
                            className={`${table.getCanPreviousPage() ? 'text-neo' : ''}`}
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                        >
                            {'<'}
                        </Button>
                        <Button
                            className={`${table.getCanNextPage() ? 'text-neo' : ''}`}
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                        >
                            {'>'}
                        </Button>
                        <Button
                            className={`${table.getCanNextPage() ? 'text-neo' : ''}`}
                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                            disabled={!table.getCanNextPage()}
                        >
                            {'>>'}
                        </Button>
                    </div>
                </>
            </TableContainer>

            <ConfirmationDeleteModal
                open={isDeleteModalOpen}
                handleClose={() => {
                    setDeleteModalOpen(false)
                }}
                onRemove={() => {
                    onRemoveRowSelection(arraySelectedId);
                }}
            />
        </>
    );
};

export default VirtualizedTable;
