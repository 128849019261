import {useState,useEffect} from 'react';
import Input from "@mui/material/Input";

const VTSearchInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
    ...props
}) => {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])


    return (
        <div>
            <Input
                {...props}
                className="min-w-0"
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </div>
    );
};

export default VTSearchInput;
