import api from "../../api";

interface RequestParams {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  data?: any;
  params?: any;
  headers?: any;
}

interface BaseQueryArgs {
  baseUrl: string;
}

export const axiosBaseQuery = ({ baseUrl }: BaseQueryArgs) => async ({ url, method, data, params, headers }: RequestParams) => {
  try {
    const result = await api({
      url: baseUrl + url,
      method,
      data,
      params,
      headers,
    });
    return { data: result.data };
  } catch (error: any) {
    return {
      error: {
        status: error.response?.status,
        data: error.response?.data || error.message,
      },
    };
  }
};


export const providesTags = (type: string) => (result: any) =>
  result
    ? [
        ...result.map(({ id }: { id: number }) => ({ type, id })),
        { type, id: 'LIST' },
      ]
    : [{ type, id: 'LIST' }];