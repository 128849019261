import EditIcon from '@mui/icons-material/Edit';
import {Button} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {getCandleLabel} from "../../../utils";
import Chip from "@mui/material/Chip";

export const dataCollectorTableColumns = (handleEditConfig, handleDelete, setIsEditMode) => [
    {
        accessorKey: 'name',
        cell: cdata => cdata.getValue(),
        header: () => <span>Name</span>,
    },
    // {
    //     accessorKey: 'period_type',
    //     cell: cdata => cdata.getValue(),
    //     header: () => <span>Period Type</span>,
    // },
    {
        accessorKey: 'period',
        cell: cdata => cdata.getValue(),
        header: () => <span>Period</span>,
    },
    {
        accessorKey: 'granularity',
        cell: cdata => (
            <Chip className="bg-brand-gray text-primary-black" label={getCandleLabel(cdata.getValue())} />
        ),
        header: () => <span>Granularity (candle size)</span>,
    },
    {
        accessorKey: 'actions',
        cell: (cdata) => (
            <div>
                <Button
                    size="small"
                    className="bg-neo text-primary-black text-xs capitalize min-w-0 mr-2"
                    type="button"
                    onClick={() => {
                        handleEditConfig(cdata.row.original.id)
                        setIsEditMode(true)
                    }}
                >
                    <EditIcon />
                </Button>
                <Button
                    size="small"
                    className="bg-primary-danger text-primary-black text-xs capitalize min-w-0"
                    type="button"
                    onClick={() => {
                        handleDelete(cdata.row.original.id)
                    }}
                >
                    <DeleteIcon />
                </Button>
            </div>
        ),
        header: () => <span>Actions</span>,
    },
]