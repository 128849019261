import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import { NavLink } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ApiIcon from '@mui/icons-material/Api';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Logout from '@mui/icons-material/Logout';
import IconButton from "@mui/material/IconButton";
import EyeIcon from '@mui/icons-material/Visibility';
import EyeOffIcon from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from "react-router-dom";
import { useGetAccountQuery } from '../../redux/api/accountApi';
import { useLogoutMutation } from '../../redux/api/authApi';
import styles from "./header.module.scss";
import { useDispatch } from 'react-redux';

interface AccountMenuProps {
    open: boolean;
    onClose: () => void;
    anchorEl: HTMLElement | null;
}

const AccountMenu: React.FC<AccountMenuProps> = ({ open, onClose, anchorEl }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: account } = useGetAccountQuery();
    const [logout, { error }] = useLogoutMutation();
    const dispatch = useDispatch();

    const [showBalance, setShowBalance] = useState(false);
    const accBalance = account?.balance?.filter((x: any) => x.currency === 'USDT')?.[0];
    const fixedBalance = accBalance ? Number(accBalance.balance_free).toFixed(2) : '0.00';

    const handleLogout = async () => {
        try {
            await logout().unwrap();
    
            localStorage.removeItem('access_token');
    
            dispatch({ type: 'authApi/logout/fulfilled' });
    
            navigate('/login');
        } catch (err) {
            console.error('Logout failed:', err);
        }
    };

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: '#01002D',
                        backgroundImage: 'none',
                        boxShadow: '3px 0 6px 0 #C9C9FF17',
                        width: '225px',
                    }
                }}
            >
                <div className={styles.menuWrapper}>
                    <div className={styles.username}>{account?.username}</div>
                    <div className={styles.balanceWrapper}>
                        <div>{t('common.balance')}</div>
                        <IconButton onClick={() => setShowBalance(prev => !prev)}>
                            {showBalance ? <EyeIcon /> : <EyeOffIcon />}
                        </IconButton>
                    </div>
                    <div className={styles.balance}>
                        {showBalance ? `${fixedBalance} ${accBalance?.currency || 'USDT'}` : '*******'}
                    </div>

                    {account?.username === "botico" && (
                        <MenuItem>
                            <ListItemIcon>
                                <AdminPanelSettingsIcon fontSize="small" />
                            </ListItemIcon>
                            <NavLink to="/admin">{t('common.admin-panel')}</NavLink>
                        </MenuItem>
                    )}
                    <MenuItem>
                        <ListItemIcon>
                            <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        <NavLink to="/account">{t('routers.account')}</NavLink>
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon>
                            <ApiIcon fontSize="small" />
                        </ListItemIcon>
                        <NavLink to="/account/apikey">{t('routers.api-management')}</NavLink>
                    </MenuItem>
                    {/* <MenuItem >
                        <ListItemIcon>
                            <SettingsOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        {t('routers.settings')}
                    </MenuItem> */}
                    <MenuItem onClick={handleLogout} className={styles.logout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        {t('routers.logout')}
                    </MenuItem>
                </div>
            </Menu>
        </>
    );
};

export default AccountMenu;
