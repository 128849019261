import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 14}
    height={props.height || 16}
    fill="none"
    viewBox="0 0 14 16"
    {...props}
  >
    <path
      fill="#CE1C06"
      d="M2.834 15.5c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.177V3H.334V1.333h4.167V.5h5v.833h4.166V3h-.833v10.833c0 .459-.163.851-.49 1.177-.326.327-.718.49-1.177.49H2.834ZM11.167 3H2.834v10.833h8.333V3Zm-6.666 9.167h1.666v-7.5H4.501v7.5Zm3.333 0h1.667v-7.5H7.834v7.5Z"
    />
  </svg>
);
export default SvgComponent;
