import React from 'react';

interface StyleAlertProps {
    type: 'error' | 'warning' | 'info' | 'success';
    text: string;
    icon?: React.ReactNode;
    width?: number;
}

const StyleAlert: React.FC<StyleAlertProps> = ({
    type,
    text,
    icon,
    width = 100,
}) => {

    const alertStyles = {
        error: { backgroundColor: '#f8d7da', color: '#721c24' },
        warning: { backgroundColor: '#fff3cd', color: '#856404' },
        info: { backgroundColor: '#EFEFFF', color: '#3F48E0' },
        success: { backgroundColor: '#d4edda', color: '#155724' },
    };

    return (
        <div
            style={{
                ...alertStyles[type],
                width: `${width}%`,
                padding: '10px 15px',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                margin: '10px 0',
                border: '1px solid transparent',
                position: 'relative',
            }}
        >
            {icon && <span style={{ marginRight: '10px' }}>{icon}</span>}
            <span style={{ flex: 1, textAlign: 'left', fontSize: 14 }}>{text}</span>
        </div>
    );
};

export default StyleAlert;
