import axios, { AxiosResponse, AxiosError, InternalAxiosRequestConfig, AxiosHeaders } from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from "./constants";
import history from "./history";

const $api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

$api.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        const token = localStorage.getItem('access_token');
        if (token) {
            (config.headers as AxiosHeaders).setAuthorization(`Bearer ${token}`);
        } else {
            delete config.headers.Authorization;
        }
        return config;
    },
    (error: AxiosError): Promise<AxiosError> => {
        toast.error('Request error');
        console.error('Request error:', error);
        return Promise.reject(error);
    },
);

$api.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
        if (response.data) {
            const { message } = response.data;
            if (message) {
                toast.success(message);
            }
        }
        return response;
    },
    async (error: AxiosError): Promise<AxiosError> => {
        if (axios.isAxiosError(error) && error.response) {
            const errorMessage = (error.response.data as { message?: string }).message;
            if (errorMessage) {
                toast.error(errorMessage);
            }

            if (error.response.status === 401) {
                localStorage.removeItem('access_token');
                history.push('/login');
            }
        } else {
            toast.error('An unexpected error occurred');
        }
        console.error('Response error:', error);
        return Promise.reject(error);
    },
);

export default $api;
