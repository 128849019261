import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 34}
    height={props.height || 46}
    fill="none"
    viewBox="0 0 34 46"
    {...props}
  >
    <path
      fill="#3F48E0"
      d="M25.778 22.473c1.643-.638 3.483-2.494 4.032-3.15a6.694 6.694 0 0 0 1.217-2.118 7.2 7.2 0 0 0 .392-2.37c0-1.443-.263-2.742-.785-3.893-.525-1.15-1.376-2.092-2.472-2.941-1.961-1.522-4.807-2.247-7.114-2.442V.615h-4.27v4.878l-3.87-.01V.614H8.638v4.853H.866V9.35c0 .84-.063 1.346.81 1.522.075.013.187.033.33.055.143.024 1.851.08 2.23.132.585.082 1.014.392 1.014 1.186v21.058c0 .654-.324 1.302-1.014 1.302-.381 0-2.087.104-2.23.129l-.33.058c-.873.173-.81.68-.81 1.519v4.334H8.64v4.738h4.267v-4.738h3.87v4.738h4.271v-4.774c1.582-.088 3.277-.332 4.626-.742 1.71-.519 2.708-1.238 3.875-2.153 1.17-.917 2.057-2.008 2.67-3.274.612-1.263.914-2.642.914-4.134 0-4.136-2.34-6.962-7.355-7.832ZM13.144 11.7h3.46c1.83 0 3.17.302 4.03.9.857.596 1.288 1.602 1.288 3.01 0 1.363-.478 2.355-1.434 2.978-.95.624-2.345.94-4.183.94h-3.161V11.7Zm9.679 20.1c-.182.499-.478.936-.885 1.312-.409.374-.953.673-1.631.898-.679.225-1.522.338-2.527.338h-4.485V26.41h4.466c.953 0 1.763.085 2.436.261.67.176 1.22.426 1.648.758.434.33.747.73.95 1.198.2.47.3.997.3 1.582 0 .56-.091 1.093-.272 1.59Z"
    />
  </svg>
);
export default SvgComponent;
