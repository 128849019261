import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || 126.611}
    height={props.width ||126.611}
    viewBox="0 0 126.611 126.611"
    {...props}
  >
    <path
      fill="#F3BA2F"
      d="m38.171 53.203 24.588-24.587 24.601 24.6 14.307-14.307L62.759 0 23.864 38.896zM13.76 48.997l14.307 14.306L13.76 77.611-.547 63.304zM38.171 73.408l24.588 24.587 24.6-24.599 14.315 14.299-.007.008-38.908 38.908-38.896-38.895-.02-.02zM126.066 63.31 111.76 77.617 97.452 63.311l14.307-14.308z"
    />
    <path
      fill="#F3BA2F"
      d="M77.271 63.298h.006L62.759 48.78 52.03 59.509h-.001l-1.232 1.233-2.543 2.543-.02.02.02.021 14.505 14.505 14.518-14.518.007-.008z"
    />
  </svg>
);
export default SvgComponent;
