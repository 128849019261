import React from "react";
import history from "../../history";
import { BoticoIcon } from "../../components/svg";
import { useTranslation } from "react-i18next";

const i18nBase = "pages.error-page.";

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center gap-4 h-screen w-full bg-primary-dark-900">
      <div className="flex items-center justify-center gap-2">
        <h1 className="text-8xl font-bold text-white">4</h1>
        <div className="w-16 h-16 border-[10px] flex justify-center items-center border-white rounded-full">
          <BoticoIcon width={22} height={34} />
        </div>
        <h1 className="text-8xl font-bold text-white">4</h1>
      </div>
      <div className="flex flex-col justify-center items-center gap-2">
        <h3 className="text-3xl fond-bold text-white">
          {t(i18nBase + "title")}
        </h3>
        <div className="flex gap-1 justify-center items-center">
          <p className="text-base font-medium text-white">
            {t(i18nBase + "description")}
          </p>
          <p
            onClick={() => history.push("/")}
            className="text-base text-secondary-main font-medium cursor-pointer underline"
          >
            {t(i18nBase + "link")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
