import {
  Dashboard,
  BotBuilder,
  TradeHistory,
  RiskStrategy,
  SignalStrategy,
  Backtesting,
} from "../svg";

interface SubMenuItem {
  name: string;
  path: string;
}

interface MenuItem {
  name: string;
  path: string;
  icon?: React.ReactNode;
  subMenu?: SubMenuItem[];
}

export const menuItems: MenuItem[] = [
  {
    name: "routers.dashboard",
    path: "/dashboard",
    icon: <Dashboard />,
  },
  {
    name: "routers.bot-builder",
    path: "/bot-builder",
    icon: <BotBuilder />,
  },
  {
    name: "routers.orders",
    path: "/trade-history",
    icon: <TradeHistory />,
  },
  {
    name: "routers.risk-management",
    path: "/risk-management",
    icon: <RiskStrategy />,
  },
  {
    name: "routers.signal-strategy",
    path: "/signal-strategy",
    icon: <SignalStrategy />,
  },
  {
    name: "routers.backtesting",
    path: "",
    icon: <Backtesting />,
    subMenu: [
      { name: "Instance", path: "/back-testing" },
      { name: "Data Collector", path: "/back-testing/collector" },
    ],
  },
];
